// src/components/SuccessModal.js
import React, { useEffect, useState } from 'react';

const SuccessModal = ({ onClose }) => {
  const [countdown, setCountdown] = useState(5); // Countdown starts from 5 seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer); // Clear the timer
          onClose(); // Call onClose when the countdown reaches 0
          return 0; // Set countdown to 0
        }
        return prev - 1; // Decrease countdown
      });
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup the timer
  }, [onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-lg font-bold mb-2 text-green-600">Registration Successful!</h2>
        <p className="mb-4">You have registered successfully.</p>
        <p className="mb-4">You will be redirected to the login page in {countdown} seconds.</p>
        <button
          onClick={onClose}
          className="mt-4 bg-theme-blue text-white rounded-md px-4 py-2"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
