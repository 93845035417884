// import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom'; // Make sure you have react-router-dom installed
// import axios from 'axios';
// import apiClient from 'axiosInstance';

// const Success = () => {
//     const location = useLocation();
//     // const end_point = 'http://localhost:5000'
//        const end_point = 'https://dosso21.com'

//     useEffect(() => {
//         const params = new URLSearchParams(location.search);
//         const transactionId = params.get('transactionId');
       
//         getDataFunction(transactionId)
        
//     }, [location]);


//     const getDataFunction = async (transactionId) => {
//         if (transactionId) {
//             const url = `/api/phone/pay/payment/status/check?transactionId=${transactionId}`;
//             try {
//                 const response = await apiClient.post(url);
    
//                 if (response.ok) {              
    
//                 const data = await response.json();
//                 console.log('Transaction stored successfully:', data);
//             }
//             } catch (error) {
//                 console.log('Error storing transaction:', error);
//             }
//         } else {
//             console.error('No transactionId provided');
//         }
//     };
    
    

//     return (
//         <div className="container">
//             <div className="row justify-content-center">
//                 <div className="col-md-6 text-center">
//                     <div className="alert alert-success text-center">
//                         <h4 className="alert-heading">Payment Successful vikram</h4>
//                     </div>
//                     <a href="/">Back to Home</a>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Success;









// import React, { useEffect } from 'react';
// import { useParams } from 'react-router-dom'; // Import useParams to get route parameters
// import apiClient from 'axiosInstance'; // Ensure this is set up correctly to include your base URL

// const Success = () => {
//     const { id: transactionId } = useParams(); // Extract the transaction ID from the URL

//     useEffect(() => {
//         getDataFunction(transactionId);
//     }, [transactionId]); // Depend on transactionId

//     const getDataFunction = async (transactionId) => {
//         if (transactionId) {
//             const url = `/api/phone/pay/payment/status/check?transactionId=${transactionId}`;
//             try {
//                 const response = await apiClient.post(url); // Use axios for the post request

//                 if (response.status === 200) {  // Check if the response is successful
//                     const data = response.data; // Access the data directly
//                     console.log('Transaction stored successfully:', data);
//                 } else {
//                     console.error('Error in response:', response.status, response.statusText);
//                 }
//             } catch (error) {
//                 console.error('Error storing transaction:', error);
//             }
//         } else {
//             console.error('No transactionId provided');
//         }
//     };

//     return (
//         <div className="container">
//             <div className="row justify-content-center">
//                 <div className="col-md-6 text-center">
//                     <div className="alert alert-success text-center">
//                         <h4 className="alert-heading">Payment Successful!</h4>
//                     </div>
//                     <a href="/">Back to Home</a>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Success;






// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom'; // Import useParams to get route parameters
// import apiClient from 'axiosInstance'; // Ensure this is set up correctly to include your base URL

// const Success = () => {
//     const { transactionId } = useParams(); // Extract the transaction ID from the URL
//     const [transactionDetails, setTransactionDetails] = useState(null); // State to hold transaction details
//     const [error, setError] = useState(null); // State to hold error messages

//     useEffect(() => {
//         getDataFunction(transactionId);
//     }, [transactionId]); // Depend on transactionId

//     const getDataFunction = async (transactionId) => {
//         if (transactionId) {
//             const url = `/api/phone/pay/payment/status/check?transactionId=${transactionId}`;
//             try {
//                 const response = await apiClient.post(url); // Use axios for the post request

//                 if (response.status === 200) {  // Check if the response is successful
//                     const data = response.data; // Access the data directly
//                     console.log('Transaction stored successfully:', data);
//                     setTransactionDetails(data.transactionDetails); // Save the transaction details to state
//                 } else {
//                     setError('Error in response');
//                     console.error('Error in response:', response.status, response.statusText);
//                 }
//             } catch (error) {
//                 setError('Error storing transaction');
//                 console.error('Error storing transaction:', error);
//             }
//         } else {
//             setError('No transactionId provided');
//             console.error('No transactionId provided');
//         }
//     };

//     return (
//         <div className="container mx-auto mt-10">
//             <div className="flex justify-center">
//                 <div className="max-w-md w-full text-center">
//                     {error ? (
//                         <div className="bg-red-500 text-white p-4 rounded-lg">
//                             <h4 className="font-bold text-lg">{error}</h4>
//                         </div>
//                     ) : (
//                         <div className="bg-green-500 text-white p-4 rounded-lg">
//                             <h4 className="font-bold text-lg">Payment Successful!</h4>
//                             <div className="mt-4 bg-white text-black p-4 rounded-lg shadow-md">
//                                 {transactionDetails && (
//                                     <div>
//                                         <p className="font-semibold">Transaction ID: <span className="font-normal">{transactionDetails?.data?.transactionId}</span></p>
//                                         <p className="font-semibold">Amount: <span className="font-normal">₹{(transactionDetails?.data?.amount / 100).toFixed(2)}</span></p>
//                                         <p className="font-semibold">Status: <span className="font-normal">{transactionDetails?.code}</span></p>
//                                         <p className="font-semibold">Payment Method: <span className="font-normal">{transactionDetails?.data?.paymentInstrument?.type}</span></p>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     )}
//                     <a className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200" href="/">Back to Home</a>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Success;





import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from 'axiosInstance';

const Success = () => {
    const { transactionId } = useParams();
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // New loading state

    useEffect(() => {
        getDataFunction(transactionId);
    }, [transactionId]);

    const getDataFunction = async (transactionId) => {
        setLoading(true); // Start loading
        if (transactionId) {
            const url = `/api/phone/pay/payment/status/check?transactionId=${transactionId}`;
            try {
                const response = await apiClient.get(url);

                if (response.status === 200) {
                    const data = response.data;
                    setTransactionDetails(data.transactionDetails);
                } else {
                    setError('Error in response');
                }
            } catch (error) {
                const message = error.response?.data?.message || 'Error storing transaction';
                setError(message);
            }
        } else {
            setError('No transactionId provided');
        }
        setLoading(false); // End loading
    };

    return (
        <div className="container mx-auto mt-10">
            <div className="flex justify-center">
                <div className="max-w-md w-full text-center">
                    {loading ? (
                        <div>Loading...</div>
                    ) : error ? (
                        <div className="bg-red-500 text-white p-4 rounded-lg">
                            <h4 className="font-bold text-lg">{error}</h4>
                        </div>
                    ) : (
                        <div className="bg-green-500 text-white p-4 rounded-lg">
                            <h4 className="font-bold text-lg">Payment Successful!</h4>
                            <div className="mt-4 bg-white text-black p-4 rounded-lg shadow-md">
                                {transactionDetails && (
                                    <div>
                                        <p className="font-semibold">Transaction ID: <span className="font-normal">{transactionDetails?.data?.transactionId}</span></p>
                                        <p className="font-semibold">Amount: <span className="font-normal">₹{(transactionDetails?.data?.amount / 100).toFixed(2)}</span></p>
                                        <p className="font-semibold">Status: <span className="font-normal">{transactionDetails?.code}</span></p>
                                        <p className="font-semibold">Payment Method: <span className="font-normal">{transactionDetails?.data?.paymentInstrument?.type}</span></p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <a className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200" href="/">Back to Home</a>
                </div>
            </div>
        </div>
    );
}

export default Success;
