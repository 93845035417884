// dosso21.com - React (Frontend)
import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom'; // For redirecting to another page after payment

const PaymentPageRateFry = () => {
  const [transaction, setTransaction] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
//   const history = useHistory();

  useEffect(() => {
    // Capture the query params from the URL
    const params = new URLSearchParams(window.location.search);
    const transactionId = params.get('transactionId');
    const amount = params.get('amount');
    const name = params.get('name');
    const mobile = params.get('mobile');
    const userId = params.get('userId');

    // Set the transaction details
    setTransaction({ transactionId, amount, name, mobile, userId });

    // Simulate triggering the payment gateway here
    if (transactionId && amount) {
      // Assuming you have a function to trigger the payment gateway
      triggerPayment(transactionId, amount,name,mobile,userId);
    }
  }, []);

  const triggerPayment = async (transactionId, amount,name,mobile,userId) => {
    try {

       const paymentResponse = await axios.post('https://dosso21.com/api/phone/pay/ratefry', {
      amount, transactionId, name,mobile,userId 
    });

      // Handle payment response
      if (paymentResponse.status === 200) {
        window.location.href = paymentResponse.data.redirectUrl;
      }
    } catch (error) {
      console.error('Payment error:', error);
      setPaymentStatus('failure');
    }
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <h1 className="text-2xl font-semibold">Processing Payment for {transaction?.name}</h1>
  </div>
  
  );
};

export default PaymentPageRateFry;
