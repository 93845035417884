
import React, { Component } from 'react';

import {
  ServicesDevelopment,
} from 'json/landingPageData';
import Header from 'parts/Header';
import Hero from 'parts/Hero';
import Service from 'parts/Service';
import Footer from 'parts/Footer';
import RegisterLoginComponent from 'elements/Form/RegisterLoginButton';


export default class LandingPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
       <RegisterLoginComponent/>
        {/* <Hero /> */}
        {/* <Service heading={"Our Social Media App Dosso21"} data={ServicesDevelopment} /> */}
        {/* <Footer /> */}
      </>
    );
  }
}
