import React from 'react';

const RegisterLoginComponent = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Welcome to Dosso21</h2>
        <div className="space-y-4">
          {/* Login Button */}
          <button
            onClick={() => window.location.href = '/login'}
            className="w-full py-2 bg-gray-700 text-white rounded-md hover:bg-blue-600 transition"
          >
            Login
          </button>

          {/* Register Button */}
          <button
            onClick={() => window.location.href = '/register-now'}
            className="w-full py-2  bg-gray-700 text-white rounded-md hover:bg-green-600 transition"
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterLoginComponent;
