// // src/Dashboard.js
// import React, { useEffect, useState } from 'react';

// import apiClient from 'axiosInstance';


// const Dashboard = () => {
//   const [walletAmount, setWalletAmount] = useState(0); // Initial wallet amount
//   const [addAmount, setAddAmount] = useState('');
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [referalCode,setReferalCode] = useState('');

//   const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwibW9iaWxlIjo4ODI0NDc2MTM5LCJpYXQiOjE3MzAyMDE4Nzl9.rdG2AeQKMfutRXWIXWnanPz1YZjkTEQcteKxVR6PXaY'; // Replace with your actual token
//  const end_point =  process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     const fetchWalletData = async () => {
//       try {
//         const response = await apiClient.post('/api/wallet/get');
        
//         // Assuming the response contains the wallet data
//         const walletData = response.data.data;
//         setWalletAmount(walletData.balance);
//         setReferalCode(response.data.yourReferalCode)
//       } catch (err) {
//         setError('Failed to fetch wallet data');
//         console.error(err);
//       }
//     };

//     fetchWalletData();
//   }, [token]);


//   const handleAddAmount = async () => {
//     const data = {
//         amount: parseFloat(addAmount),
//         MUID: "MUID" + Date.now(),
//         transactionId: 'T' + Date.now(),
//     };

//     try {
//         setLoading(true);
//         // proceed with payment
//         const response = await apiClient.post(`/api/phone/pay`, data);
//         window.location.href = response.data.redirectUrl; // Redirect to payment gateway
//         setLoading(false)
//     } catch (error) {
//         setError('Payment initiation failed. Please try again.');
//         console.error(error);
//     } finally {
//         setLoading(false);
//     }
// };


//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-100">
//       <div className="bg-white p-8 rounded-lg shadow-md w-80">
//         <h1 className="text-2xl font-semibold mb-4">User Dashboard</h1>
//         {error && <p className="text-red-500 mb-4">{error}</p>}
//         <div className="mb-4">
//           <h2 className="text-lg">Wallet Amount</h2>
//           <p className="text-xl font-bold">{walletAmount.toFixed(2)}</p>
//         </div>
//         <div className="mb-4">
//           <input
//             type="number"
//             value={addAmount}
//             onChange={(e) => setAddAmount(e.target.value)}
//             placeholder="Add Amount"
//             className="border rounded-md p-2 w-full"
//           />
//         </div>
      
//         <button
//           onClick={handleAddAmount}
//           className="bg-blue-500 text-white rounded-md p-2 w-full hover:bg-blue-600"
//         >
//        {loading ? 'Processing...' : 'Add Money'}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;






import React, { useEffect, useState } from 'react';
import apiClient from 'axiosInstance';

const Dashboard = () => {
  const [walletAmount, setWalletAmount] = useState(0);
  const [addAmount, setAddAmount] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [referalCode, setReferalCode] = useState('');
  const [isCopied, setIsCopied] = useState(false); // State for copy status

  const token = 'your_token_here'; // Replace with your actual token
  const end_point = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response = await apiClient.post('/api/wallet/get');
        const walletData = response.data.data;
        setWalletAmount(walletData.balance);
        setReferalCode(response.data.yourReferalCode);
      } catch (err) {
        setError('Failed to fetch wallet data');
        console.error(err);
      }
    };

    fetchWalletData();
  }, [token]);

  const handleAddAmount = async () => {
    const data = {
      amount: parseFloat(addAmount),
      MUID: "MUID" + Date.now(),
      transactionId: 'T' + Date.now(),
    };

    try {
      setLoading(true);
      const response = await apiClient.post(`/api/phone/pay`, data);
      window.location.href = response.data.redirectUrl;
    } catch (error) {
      setError('Payment initiation failed. Please try again.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referalCode)
      .then(() => {
        setIsCopied(true); // Set copied status to true
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  const shareReferralCode = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Check out this referral code!',
        text: `Use my referral code: ${referalCode}`,
        url: 'https://dosso21.com/register-now'
      })
      .then(() => console.log('Share successful'))
      .catch(err => console.error('Share failed:', err));
    } else {
      alert('Share not supported in this browser.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 mt-52">
      <div className="bg-white p-8 rounded-lg shadow-md w-80">
        <h1 className="text-2xl font-semibold mb-4">User Dashboard</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <h2 className="text-lg">Wallet Amount</h2>
          <p className="text-xl font-bold">{walletAmount.toFixed(2)}</p>
        </div>
        <div className="mb-4">
          <input
            type="number"
            value={addAmount}
            onChange={(e) => setAddAmount(e.target.value)}
            placeholder="Add Amount"
            className="border rounded-md p-2 w-full"
          />
        </div>
        
        <button
          onClick={handleAddAmount}
          className="bg-theme-blue text-white rounded-md p-2 w-full hover:bg-blue-600"
        >
          {loading ? 'Processing...' : 'Add Money'}
        </button>

        <div className="mt-6">
          <h2 className="text-lg">Your Referral Code</h2>
          <div className="flex items-center justify-between">
            <p className="text-xl font-bold">{referalCode}</p>
            <button onClick={copyToClipboard} className="text-blue-500">
              {isCopied ? '✔️' : '📋'} {/* Change icon based on copy status */}
            </button>
          </div>
          {isCopied && <p className="text-green-500 mt-2">Copied!</p>} {/* Success message */}
          <button
            onClick={shareReferralCode}
            className="mt-2 bg-theme-blue text-white rounded-md p-2 w-full hover:bg-green-600"
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
